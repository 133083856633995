.Input_animation {
    height:100px;
    overflow:hidden;
    animation-duration: 8s;
    animation-iteration-count:infinite;
    position: relative;
    animation-fill-mode:forwards;
    animation-name: show;
    background-color: transparent;
  }
  
   .Input_animation > div > div {
    text-align: left;
    height:35px;
    display:inline-block;
  } 
  
  @keyframes show {
    0% {bottom:-30px;}
    10% {bottom:0px;}
    30%{bottom:0px;}
    40%{bottom: 30px;}
    70%{bottom: 30px;}
    80%{bottom:60px;}
    90%{bottom:60px;}
    100%{bottom: 90px;}
  } 

  .hover-search{
    background-color: #DD296E;
}

.InputHover:hover{
border:1.5px solid #DD296E !important;
border-right: none !important;
}

.hover-search:hover {
    background: linear-gradient(90deg, #CF022B 0%, #EA5599 100%);    
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.suggestion-box{
  width: 500px;
  /* border: "1px solid red"; */
}

@media(min-width: 1100px){
  .suggestion-box{
    width: 600px;
  }
}
@media(min-width: 1400px){
  .suggestion-box{
    width: 700px;
    /* border: 1px solid red; */
  }
}