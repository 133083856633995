* {
    width: 100%;
    height: 100%;
    margin: 0px;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    background-color: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
body{
  width:100% !important;
  height: 100% !important;
}
.FontRoboto{
  font-family: 'Roboto Slab', serif;
}
.FontInter{
  font-family: 'Inter', sans-serif;
}

.back-button-hover:hover{
  background: #EBEAF4 !important;
}
.article :hover {
  color: #0e090c;
  background: #ebeaf4;
}
.back-button-hover-chatheader:hover{
  background: #DD296E !important;
}

.hoverclass:hover {
    background:  #E6E6E6 !important;
    color: #4A4A49 !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.hoveclass:active{
  background:  #D9D9D9 !important;
    color: #4A4A49 !important;
}

::-webkit-scrollbar {
    width: 4px;
    display: block;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #EDEDED; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C1C1C1; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:  #C1C1C1; 
  }

.epr-header{
  height: fit-content;
}

.epr-header-overlay{
  height: 'fit-content';
}
.epr-icn-search{
  height: fit-content;
}
.epr-skin-tones + .epr-horizontal{
  height: fit-content;
}
.EmojiPickerReact .Flex.FlexRow{
  height: fit-content;
}
.epr-emoji-list{
  height: fit-content;
}

.mainLodingSN{
  border: 3px solid white;
  border-radius: 50%;
  border-top: 3px solid rgb(53, 52, 52);
  opacity: 25%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}
.Skeleton{
  animation-name: loadingSkeleton;
  animation-duration: 2s;
  background: linear-gradient(90deg, rgba(182, 176, 206, 0.00) 0%, #B6B0CE 48.44%, rgba(182, 176, 206, 0.00) 100%); 
  position: relative;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes loadingSkeleton {
  0%   {left:0px; top:0px;}
  100%  { left:250px; top:0px;}
}