.switch {
  display: inline-block;
  width: 35px;
  height: 15px;
  background-color: lightgray; 
  border-radius: 15px;
  cursor: pointer;
  z-index: 1
}

.switch input[type="checkbox"] {
  display: none;
}
.switch.selected {
  background-color: #a9ebe1; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: gray;
  border-radius: 100%;
  transition: 0.4s;
  z-index: 1;
  top: 39.2%;
}
@media (max-width: 480px) {
  .slider:before {
      top: 210px;
  }
}
.switch.selected .slider:before {
  right: 25px;
  background-color: #00A188;
  z-index: 1;
}
@media (max-width: 480px) {
  .switch.selected .slider:before {
    right: 10%;
  }
}
