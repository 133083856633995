.welcome{
    width:30px;
    height: 30px;
    background-color: #F18FBC;
    animation-duration: 2s;
    border-radius: 100%;
    animation-iteration-count:infinite;
    position: relative;
    animation-fill-mode:forwards;
    animation-name: welcome11;
  }
  .welcome1{
    width:30px;
    height: 30px;
    background-color: #FFFFFF;
    border: 2px #0E090C solid ;
    animation-duration: 2s;
    border-radius: 100%;
    animation-iteration-count:infinite;
    position: relative;
    animation-fill-mode:forwards;
    animation-name: welcome12;
  }
  .welcome2{
    width:30px;
    height: 30px;
    background-color: #675B98;
    animation-duration: 2s;
    border-radius: 100%;
    animation-iteration-count:infinite;
    position: relative;
    animation-fill-mode:forwards;
    animation-name: welcome13;
  }
  
  @keyframes welcome11 {
   0%{
    top: 90px;
    left: 20px;
   }
   30%{
    top: 160px;
    left: 20px;
   }
   60%{
    top: 30px;
    left: 20px;
   }
   90%{
    top: 70px;
    left: 20px;
   }
   100%{
    top: 90px;
    left: 20px;
   }
  } 
  
  @keyframes welcome12 {
    0%{
     top: 90px;
     left: 45px;
    }
    25%{
     top: 30px;
     left: 45px;
    }
    55%{
      top: 70px;
      left: 45px;
    }
    75%{
      top: 160px;
      left: 45px;
    }
    100%{
     top: 90px;
     left: 45px;
    }
   } 
  
   @keyframes welcome13 {
    0%{
     top: 90px;
     left: 70px;
    }
    30%{
     top: 30px;
     left: 70px;
    }
    60%{
     top: 160px;
     left: 70px;
    }
    90%{
     top: 70px;
     left: 70px;
    }
    100%{
     top: 90px;
     left: 70px;
    }
   } 